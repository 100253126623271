<template>
  <div>
    <div
      id="carouselExampleSlidesOnly"
      class="carousel slide data-slide-to"
      data-ride="carousel "
    >
      <div class="carousel-inner data-slide-to">
        <div class="carousel-item active" id="one" data-slide="next">
          <img
            class="d-block w-100"
            :src="this.galeriaFotos.casquinhas.url"
            alt="First slide"
          />
        </div>
        <div class="carousel-item" id="two">
          <img
            class="d-block w-100"
            :src="this.galeriaFotos.pizzas.url"
            alt="Second slide"
          />
        </div>
        <div class="carousel-item" id="three">
          <img
            class="d-block w-100"
            :src="this.galeriaFotos.combos.url"
            alt="Third slide"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CarrosselComponent",
  props: ["galeriaFotos"],
  data() {
    return {
      album: this.galeriaFotos,
    };
  },
  watch() {},
  created() {},
};
</script>
<style scoped>
img {
  margin: auto auto;
  max-width: 1080px;
}
</style>
