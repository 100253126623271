<template>
  <div>
    <img src="/assets/img/banner/bannerap.jpg" alt="" />
    <img src="/assets/img/banner/banner2.jpg" alt="" />
    {{ fullname }}
    <button @click="puxaLista">PuxaLista</button>
  </div>
</template>

<script>
export default {
  name: "BannerView",

  data() {
    return {
      nome: "Marlon",
      sobrenome: "Souza",
    };
  },
  methods: {},
  computed: {
    fullname() {
      return this.nome + " " + this.sobrenome;
    },
  },
};
</script>

<style scoped>
img {
  margin-bottom: -4px;
}
</style>
