import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import TransmissaoView from "@/views/TransmissaoView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/cardapio",
    name: "cardapio",
    component: HomeView,
  },
  {
    path: "/franquia",
    name: "franquia",
    component: HomeView,
  },
  {
    path: "/licenciamento",
    name: "licenciamento",
    component: HomeView,
  },
  {
    path: "/faleconosco",
    name: "faleconosco",
    component: HomeView,
  },
  {
    path: "/associados",
    name: "associados",
    component: HomeView,
  },
  {
    path: "/sobrenos",
    name: "sobrenos",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "sobrenos" */ "../views/SobreNosView.vue"),
  },
  {
    path: "/transmissao",
    name: "transmissao",
    component: TransmissaoView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
