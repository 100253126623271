<template>
  <div class="home">
    <HeaderView />

    <BannerView caminhoImagem="../assets/img/banner/b800x600.svg" />
    <FooterView />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderView from "@/components/HeaderView.vue";
import FooterView from "@/components/FooterView.vue";
import BannerView from "@/components/BannerView.vue";

export default {
  name: "HomeView",
  data() {
    return {};
  },
  components: {
    HeaderView,
    FooterView,
    BannerView,
  },
};
</script>
<style>
.home {
  text-align: center;
}
</style>
