<template>
  <div>
    <CarrosselComponent :galeriaFotos="this.galeria" />
  </div>
</template>

<script>
import CarrosselComponent from "@/components/CarrosselComponent.vue";

export default {
  props: ["galeriaFotos"],
  components: {
    CarrosselComponent,
  },
  data() {
    return {
      galeria: {},
    };
  },
  methods: {
    getImg() {
      fetch("./database/listaTransmissao.json")
        .then((r) => r.json())
        .then((body) => {
          this.galeria = body;
        });
    },
  },
  created() {
    this.getImg();
  },
};
</script>

<style scoped></style>
